<template>
<!--  <div id="app" v-if="accessConfirm"></div>-->
  <div id="app">
    <SvgSprite/>
    <Disclaimer/>
    <Header/>
    <router-view/>
    <Footer/>
<!--    <div id="app" v-else>
        <AccessConfirmation
            :reject="accessRejected"
            @clickYes="successAccess"
            @clickNo="errorAccess"
        />
    </div>-->
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import VModal from "vue-js-modal";
import Vuelidate from "vuelidate";
// import AccessConfirmation from './blocks/access-confirmation/access-confirmation.vue';
import SvgSprite from "@/elements/svg-sprite/svg-sprite.vue";
import Disclaimer from "@/blocks/disclaimer/disclaimer.vue";
import Header from "@/blocks/header/header.vue";
import VueRouter from "vue-router";
import Footer from "@/blocks/footer/footer.vue";

Vue.use(VModal);
Vue.use(Vuelidate);
Vue.use(VueRouter);

@Component({
    components: {
        SvgSprite,
        Disclaimer,
        // AccessConfirmation,
        Header,
        Footer,
    },
    // data() {
    //   return {
    //     accessConfirm: false,
    //     accessRejected: false,
    //   };
    // },
    // methods: {
    //   checkAccess() {
    //     if (localStorage.getItem('app_access') && localStorage.getItem('app_access') === 'true') {
    //       this.accessConfirm = true;
    //       this.accessRejected = false;
    //     } else if (
    //       localStorage.getItem('app_access')
    //       && localStorage.getItem('app_access') === 'false'
    //     ) {
    //       this.accessConfirm = false;
    //       this.accessRejected = true;
    //     }
    //   },
    //   successAccess() {
    //     this.accessConfirm = true;
    //     this.accessRejected = false;
    //     localStorage.setItem('app_access', this.accessConfirm);
    //   },
    //   errorAccess() {
    //     this.accessConfirm = false;
    //     this.accessRejected = true;
    //     localStorage.setItem('app_access', this.accessConfirm);
    //   },
    // },
    // beforeMount() {
    //   this.checkAccess();
    // },
})

export default class App extends Vue {}
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}
</style>
