
import { Component, Vue } from "vue-property-decorator";
import ButtonWatchMore from "../../elements/button/button-watch-more/button-watch-more.vue";
import modalInfo from "@/components/modal/modal/config";

@Component({
    components: {
        ButtonWatchMore,
    },
    props: ["product", "type"],
    methods: {
        modalInfoOpen(product) {
            modalInfo(this, product);
        },
    },
})
export default class ProductItem extends Vue {}
