<template lang="pug">
  .modal-wrapper
    slot
</template>

<script>
export default {
    name: "Modal",
    props: ["title", "name", "variant"],
    components: {
    },
    methods: {
        someEvent() {
            this.$modal.hide(this.name);
        },
    },
};
</script>

<style lang="scss">
</style>
