import * as R from "ramda";
import log from "./log";

const transforms = [
    [["name"], ["drugName"]],
    [["uuid"], ["drugUuid"]],
    [["mnn", "name"], ["mnnName"]],
    [["mnn", "uuid"], ["mnnUuid"]],
    [["isTeva"], ["isTeva"]],
    [["form"], ["form"]],
    [["dosage"], ["dosages"]],
    [["certificate", "date"], ["certDate"]],
    [["certificate", "number"], ["certNumber"]],
    [["certificate", "owner", "name"], ["ownerName"]],
    [["certificate", "owner", "country"], ["ownerCountry"]],
];

const transformKey = (data: any) => {
    log("data", data);
    return R.reduce((acc, keys) => {
        const TO = keys[1];
        const FROM = keys[0];
        const value = R.path(FROM, data);
        return R.assocPath(TO, value, acc);
    }, {}, transforms);
};

const transform = (data: any) => data.map(transformKey);

export default transform;
