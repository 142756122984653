
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
    },
    props: ["pagination", "onClick"],
    methods: {
    },
})
export default class Pagination extends Vue {}
