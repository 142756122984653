const dateFormat = (iso: string) => {
    const options: any = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
    };
    const date = new Date(iso);
    return date.toLocaleString("ru", options);
};

export default dateFormat;
