import axios from "axios";
// import { examplesDTO, searchDTO } from '@/config/dto';

const apiRequestProducts = (params: any, success: any, error: any) => {
    axios
        .get(
            `${process.env.VUE_APP_API_URL}/api/drugs`,
            {
                params,
            },
        )
        .then(success)
        .catch(error);
};

const apiRequestExamples = (params: any, success: any, error: any) => {
    axios
        .get(
            `${process.env.VUE_APP_API_URL}/api/search`,
            {
                params,
            },
        )
        .then(success)
        .catch(error);
};

const apiRequestDrugForms = (params: any, success: any, error: any) => {
    axios
        .get(
            `${process.env.VUE_APP_API_URL}/api/drugs/gen-forms`,
            {
                params,
            },
        )
        .then(success)
        .catch(error);
};

const apiStatistic = (success: any, error?: any) => {
    axios
        .post(`${process.env.VUE_APP_API_URL}/api/drugs/statistic`)
        .then(success)
        .catch(error);
};

export {apiRequestProducts, apiRequestExamples, apiRequestDrugForms, apiStatistic};
