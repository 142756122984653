/* eslint-disable no-plusplus */
import * as R from "ramda";
import transform from "@/config/transform";
import log from "@/config/log";

const searchDTO = (data: any) => {
    log("searchDTO", data);
    // PRODUCTS
    const products = transform(R.path(["data", "data"], data) || []);
    log("products", products);
    // CURRENT PAGE
    const curPage: number = R.path(["data", "currentPage"], data) || 1;
    log("currentPage", curPage);
    // LAST PAGE
    const lastPage: number = R.path(["data", "lastPage"], data) || 1;
    log("lastPage", lastPage);
    // PAGINATION
    let pagination = [];
    const buttonConfig = (n: number) => {
        const configInner: any = {};
        configInner.type = n === 0 ? "separator" : "button";
        configInner.page = n === 0 ? "..." : n;
        configInner.isActive = curPage === n ? "active" : "";
        return configInner;
    };
    if (curPage === 1 && lastPage === 1) {
        pagination = [];
    } else if (lastPage > 1 && lastPage < 7) {
        for (let i = 1; i < lastPage + 1; i++) {
            pagination.push(buttonConfig(i));
        }
    } else if (lastPage > 6) {
        if (curPage === 1 || curPage === 2 || curPage === 3) {
            pagination.push(buttonConfig(1));
            pagination.push(buttonConfig(2));
            pagination.push(buttonConfig(3));
            pagination.push(buttonConfig(4));
            pagination.push(buttonConfig(0));
            pagination.push(buttonConfig(lastPage));
        } else if (curPage === lastPage || curPage === (lastPage - 1) || curPage === (lastPage - 2)) {
            pagination.push(buttonConfig(1));
            pagination.push(buttonConfig(0));
            pagination.push(buttonConfig(lastPage - 3));
            pagination.push(buttonConfig(lastPage - 2));
            pagination.push(buttonConfig(lastPage - 1));
            pagination.push(buttonConfig(lastPage));
        } else {
            pagination.push(buttonConfig(1));
            pagination.push(buttonConfig(0));
            pagination.push(buttonConfig(curPage - 1));
            pagination.push(buttonConfig(curPage));
            pagination.push(buttonConfig(curPage + 1));
            pagination.push(buttonConfig(0));
            pagination.push(buttonConfig(lastPage));
        }
    }
    log("pagination", pagination);
    return {
        products,
        pagination,
        curPage,
    };
};

const examplesDTO = (data: any) => {
    log("examplesDTO", data);
    const result = R.path(["data"], data) || [];
    log("result", result);
    return result;
};

const formsDTO = (data: any) => {
    log("formsDTO", data);
    const result = R.path(["data"], data) || [];
    log("result", result);
    return result;
};

export { examplesDTO, searchDTO, formsDTO };
