<template lang="pug">
  .section.products
    .products__wrapper
      form.products__search-panel.products__search-panel_extended(
        @submit.prevent="submitHandler"
      )
        .products__search-container
          input.products__search-field(
            @input="inputHandler"
            id="searchField"
            placeholder="Введите название или действующее вещество"
            autocomplete="off"
          )
          IconSearch(
            variant="icon-search--inner"
          )
          ul.products__examples-container(
            v-click-outside="hideExamplesContainer"
            v-if='(examples && examples.length > 0)'
          )
            li.products__examples-item(
              v-for="item in examples"
            )
              button.products__examples-button(
                @click='selectExample(item)'
                type="button"
              )
                span.product-type(
                  v-if='item.type === "drug"'
                ) препарат
                span.product-type(
                  v-if='item.type === "mnn"'
                ) вещество
                span {{ item.name }}
          ul.products__examples-container(
            v-click-outside="hideExamplesContainer"
            v-if='(examples && examples.length === 0)'
          )
            li.products__examples-item
              p.products__exaples-empty
                | Не найдено препарата или вещества соответствующего вашему запросу.

        .products__drug--form-container
          .products__drug--form-label(
            @click.stop='toggleDrugFormsList'
            :class= "{_disabled: !drugFormsList, _error: drugFormsError}"
          )
            span(
              v-if='drugForm === ""'
            ) Форма выпуска
            span(
              v-else
            ) {{ drugForm }}
            svg
              use(href="#btn-chewron-down")
          .products__drug--form-list(
            v-click-outside="hideDrugFormsList"
            v-if='(drugFormsListShow && drugFormsList && drugFormsList.length > 0)'
          )
            Scroller
              .products__drug--form-item(
                @click='selectDrugForm(item)'
                v-for="item in drugFormsList"
              ) {{ item.genForm }}

      .products__control-panel
        .products__bread-crumbs МНН (группировочное или химическое наименование): {{mnn}}
        ViewChanger(
          :viewTypeTile = 'viewTypeTile'
          :viewTypeLine = 'viewTypeLine'
        )
      .products__container(
        :class='viewType'
      )
        ProductItem(
          v-for="product in products"
          :product='product'
          :type='viewType'
        )
      Pagination(
        :pagination='pagination'
        :onClick='changePaginationPage'
      )
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import ClickOutside from "vue-click-outside";
import { examplesDTO, searchDTO, formsDTO } from "@/config/dto";
import IconSearch from "@/elements/icon/icon-search/icon-search.vue";
import ViewChanger from "@/components/view-changer/view-changer.vue";
import ProductItem from "@/components/product/product.vue";
import Pagination from "@/components/pagination/pagination.vue";
import Scroller from "@/elements/scroller/scroller.vue";
import { apiRequestExamples, apiRequestProducts, apiRequestDrugForms } from "@/config/api";

@Component({
    components: {
        IconSearch,
        ViewChanger,
        ProductItem,
        Pagination,
        Scroller,
    },
    data() {
        return {
            products: [],
            examples: false,
            searchText: "",
            mnn: "",
            uuidDrug: "",
            uuidSubstance: "",
            viewType: "products__container-line",
            pagination: [],
            currentPage: 1,
            countDown: false,
            drugForm: "",
            drugFormsError: false,
            drugFormsList: false,
            drugFormsListShow: false,
        };
    },
    methods: {
    // FREE SEARCH
        getFreeSearchFromApi() {
            const searchString = document.querySelector("#searchField").value;
            const params = {};
            this.currentPage = 1;
            params.page = 1;
            this.searchText = searchString;
            if (searchString) params.search = searchString;
            apiRequestProducts(params, this.getProductsSuccess, this.getProductsError);
        },
        selectExample(item) {
            document.querySelector("#searchField").value = item.name;
            this.examples = false;
            this.drugFormsError = false;
            this.drugFormsList = false;
            this.drugFormsListShow = false;
            this.searchText = item.name;
            const params = {};
            // params.page = 1;
            // params.search = item.name;
            if (item.type === "mnn") {
                this.uuidSubstance = item.uuid;
                this.uuidDrug = "";
                params.mnn = item.name;
            }
            if (item.type === "drug") {
                this.uuidDrug = item.uuid;
                this.uuidSubstance = "";
                params.drug = item.name;
            }
            // apiRequestProducts(params, this.getProductsSuccess, this.getProductsError);
            apiRequestDrugForms(params, this.getFormsSuccess, this.getFormsError);
        },
        getProductsSuccess(response) {
            const { products, pagination, currentPage } = searchDTO(response);
            this.products = products;
            this.pagination = pagination;
            this.currentPage = currentPage;
            this.mnn = this.products[0].mnnName;
        },
        getProductsError(error) {
            console.log(error);
        },
        // EXAMPLES
        getExamplesFromApiSuccess(response) {
            this.examples = examplesDTO(response);
        },
        getExamplesFromApiError(error) {
            console.log(error);
        },
        getExamplesFromApi(value) {
            const params = {
                search: value,
            };
            apiRequestExamples(params, this.getExamplesFromApiSuccess, this.getExamplesFromApiError);
        },
        hideExamplesContainer() {
            this.examples = false;
        },
        // ФОРМА ВЫПУСКА
        getFormsSuccess(response) {
            this.drugFormsList = formsDTO(response);
            this.drugFormsListShow = true;
        },
        getFormsError(error) {
            console.log(error);
        },
        selectDrugForm(item) {
            this.drugForm = item.genForm;
            this.drugFormsListShow = false;

            const params = {};
            params.page = 1;
            params.search = this.searchText;
            params.genForm = item.genForm;

            if (this.uuidSubstance !== "") {
                // this.uuidDrug = '';
                params.mnn = this.uuidSubstance;
            }
            if (this.uuidDrug !== "") {
                // this.uuidSubstance = '';
                params.drug = this.uuidDrug;
            }
            apiRequestProducts(params, this.getProductsSuccess, this.getProductsError);
        },
        toggleDrugFormsList() {
            this.drugFormsListShow = !this.drugFormsListShow;
        },
        hideDrugFormsList() {
            this.drugFormsListShow = false;
        },
        // PAGINATION
        changePaginationPage(page) {
            const params = {};
            params.page = page;
            if (this.searchText) params.search = this.searchText;
            if (this.drugForm) params.genForm = this.drugForm;
            if (this.uuidDrug) params.drug = this.uuidDrug;
            if (this.uuidSubstance) params.mnn = this.uuidSubstance;
            apiRequestProducts(params, this.getProductsSuccess, this.getProductsError);
        },
        // SUBMIT
        submitHandler() {
            // if (!this.drugFormsList && this.examples && this.examples.length !== 0) {
            //   this.drugFormsError = true;
            // }
            this.examples = false;
            this.uuidDrug = "";
            this.uuidSubstance = "";
            this.drugForm = "";
            this.drugFormsError = false;
            this.drugFormsListShow = false;
            this.drugFormsList = false;
            this.getFreeSearchFromApi();
        },
        // INPUT
        inputHandler(event) {
            const { value } = event.target;
            this.examples = false;
            this.drugForm = "";
            this.drugFormsList = false;
            this.drugFormsError = false;
            this.drugFormsListShow = false;
            if (this.countDown) {
                clearTimeout(this.countDown);
            }
            if (value.length > 1) {
                this.countDown = setTimeout(
                    () => { this.getExamplesFromApi(value); },
                    700,
                );
            }
        },
        viewTypeTile() {
            this.viewType = "";
        },
        viewTypeLine() {
            this.viewType = "products__container-line";
        },
    },
    directives: {
        ClickOutside,
    },
    // mounted() {
    //   this.getFreeSearchFromApi();
    // },
})
export default class Products extends Vue {}
</script>

<style lang="scss">
@import "../../styles/scss/main.scss";
@import "./products.scss";
</style>
