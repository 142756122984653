
import { Component, Vue } from "vue-property-decorator";
import LogoTeva from "@/elements/logo/logo-teva/logo-teva.vue";
import Button from "@/elements/button/button/button.vue";

@Component({
    components: {
        LogoTeva,
        Button
    },
    props: [
        "modalAuthOpen",
    ],
    data() {
        return {
            isOpened: false
        };
    }
})
export default class Header extends Vue {}
