<template lang="pug">
  footer.footer
    .footer__wrapper
      nav.footer__nav
        router-link(
          v-if="!isHomePage"
          to="/"
          class="footer__link"
        ) На главную
        router-link(
          to="/about"
          class="footer__link"
        ) О Teva
        router-link(
          to="/contacts"
          class="footer__link"
        ) Контакты
      div.approval_code Код одобрения FLP-RU-NP-00004 - HCP-12.2023
</template>

<script>
/* eslint-disable */
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  data() {
    return {
      isHomePage: false,
    };
  },
  mounted() {
    this.updateIsHome();
    this.$router.afterEach(() => {
      this.updateIsHome();
    });
  },
  methods: {
    updateIsHome() {
      this.isHomePage = this.$router.currentRoute.path === '/';
    },
  }
})
export default class Footer extends Vue {
}
</script>

<style lang="scss">
@import "../../styles/scss/main.scss";
@import "./footer.scss";
</style>
