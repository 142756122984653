<template lang="pug">
  Modal(
    name='modal-info'
  )
    .modal-dialog.modal-dialog-centered
      .modal-content
        .modal-header
          .product-pretitle Торговое название
          .product-title {{data.drugName}}
          .product-group МНН (группировочное или химическое наименование)
          .product-contain {{data.mnnName}}
        .modal-body

          .product-description(
             v-if='data.form'
          )
            p.grey Лекарственная форма
            p.black {{data.form}}

          .product-description(
            v-if='data.dosages'
          )
            p.grey Дозировка
            ul.black
              li(
                v-for='doze in data.dosages'
              ) {{doze}} &nbsp;

          .product-description(
            v-if='data.ownerName'
          )
            p.grey Владелец РУ
            p.black {{data.ownerName}}

          .product-description(
            v-if='data.ownerCountry'
          )
            p.grey Страна
            p.black {{data.ownerCountry}}

          .product-description(
            v-if='data.certNumber'
          )
            p.grey № РУ
            p.black {{data.certNumber}}

          .product-description(
            v-if='data.certDate'
          )
            p.grey Дата РУ
            p.black {{ date(data.certDate)}}

          .button-container
            button.button.button-modal-footer(
              @click='modalClose'
            ) Закрыть
</template>

<script>
import Modal from "@/components/modal/modal/modal.vue";
import dateFormat from "@/config/date";

export default {
    name: "ModalAuth",
    components: {
        Modal,
    },
    props: ["data"],
    methods: {
        modalClose() {
            this.$modal.hide("modal-info");
        },
        date(date) {
            return dateFormat(date);
        },
    },
};
</script>

<style lang="scss">
@import "../../../styles/scss/main.scss";

.vm--modal {
  display: flex;
  align-items: center;
  position: static !important;
  width: 100% !important;
  height: auto !important;
  min-height: 100vh !important;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: transparent !important;
  box-shadow: none !important;
}

.vm--overlay {
  background: rgba(0, 0, 0, 0.5) !important;
}

.vm--container {
  overflow-y: auto;
}

.modal-info {
  .modal-wrapper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 999;
    width: 100%;
    max-width: 46.875rem;
  }

  .modal-dialog {
  }

  .modal-content {
    padding: 0;
    background-color: transparent;
    border: 0;
    box-shadow: 0 0 1rem rgba(0,0,0,0.5);
    border-radius: .9375rem;
  }

  .modal-header {
    padding-left: 5.4375rem;
    padding-right: 5rem;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    border-top-left-radius: 0.9375rem;
    border-top-right-radius: 0.9375rem;
    background-color: #00a03b;
    text-align: left;
  }

  .product-pretitle {
    font-family: $font-primary;
    font-size: 1rem;
    line-height: 1.5625rem;
    color: #afcb37;
    font-weight: 400;
  }

  .product-title {
    font-family: $font-primary;
    color: $color-white;
    font-size: 2.1875rem;
    line-height: 2.6875rem;
    font-weight: 700;
  }

  .product-group {
    margin-top: 1.5625rem;
    font-family: $font-primary;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #afcb37;
    font-weight: 400;
  }

  .product-contain {
    margin-top: 0.25rem;
    font-family: $font-primary;
    font-size: 1rem;
    line-height: 1.25rem;
    color: $color-white;
    font-weight: 400;
  }

  .modal-body {
    padding-top: 2.125rem;
    padding-left: 5.4375rem;
    padding-right: 5.4375rem;
    padding-bottom: 3.125rem;
    background-color: $color-white;
    border-bottom-left-radius: 0.9375rem;
    border-bottom-right-radius: 0.9375rem;
  }

  .product-description {
    font-family: $font-primary;
    font-weight: 400;

    &:nth-child(n + 2) {
      margin-top: 1.5rem;
    }

    .grey {
      font-size: 1rem;
      line-height: 1.25rem;
      color: #999;
      margin-bottom: 0;
    }

    .black {
      color: $color-black;
      font-size: 1rem;
      line-height: 1.25rem;
      margin-bottom: 0;

      li {
        &:nth-child( n + 2) {
          padding-top: 0.25rem;
        }
      }
    }

    .grey + .black {
      margin-top: 0.25rem;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }
}

@include media-tablet {

  .modal-info {
    .modal-dialog {
    }

    .modal-wrapper {
      max-width: 45rem;
    }
  }
}

@include media-tablet-vertical {
  .modal-info {
    .modal-dialog {
    }

    .modal-wrapper {
      max-width: 35rem;
    }
  }
}

@include media-mobile {

  .modal-info {
    .modal-dialog {
    }

    .modal-wrapper {
      max-width: 18.75rem;
    }

    .modal-header {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }

    .product-title {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    .modal-body {
      padding-top: 1.875rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      padding-bottom: 1.875rem;
    }

    .button-container {
      margin-top: 1.875rem;
    }
  }
}

</style>
