
import { Component, Vue } from "vue-property-decorator";

@Component({
    name: "ButtonWatchMore",
    props: ["variant"],
    methods: {
        clickHandler() {
            console.log("===clickHandler===");
        },
    },
})
export default class ButtonWatchMore extends Vue {}
