import Router from "vue-router";
import Index from "@/blocks/index/index.vue";
import Products from "@/blocks/products/products.vue";
import About from "@/blocks/about/about.vue";
import Contacts from "@/blocks/contacts/contacts.vue";

const routes = [
    {
        path: "/",
        name: "Index",
        component: Index,
    },
    {
        path: "/products",
        name: "Products",
        component: Products,
    },
    {
        path: "/about",
        name: "About",
        component: About,
    },
    {
        path: "/contacts",
        name: "Contacts",
        component: Contacts,
    },
    {
        path: "*",
        component: Index,
    },
];

const router = new Router({
    mode: "history",
    routes,
});

export default router;
